// components/PostDetail.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPost } from '../actions/blogActions';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet za meta tagove
import styles from './PostDetail.module.css';
import BlogTestimonials from './Blog/BlogTestimonials';

const PostDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Pristupanje pojedinačnom postu, loading statusu i grešci iz stanja
  const post = useSelector((state) => state.post.post);
  const loading = useSelector((state) => state.post.loading);
  const error = useSelector((state) => state.post.error);

  useEffect(() => {
    dispatch(fetchPost(id)); // Učitavanje pojedinačnog posta po ID-u
  }, [dispatch, id]);

  if (loading) return <p>Učitavanje...</p>;
  if (error) return <p>Došlo je do greške: {error}</p>;

  return (
    <div className={styles.postDetail}>
      {post ? (
        <>
          <Helmet>
            <title>{post.title.rendered}</title> {/* Dinamički title */}
            <meta property="og:title" content={post.title.rendered} /> {/* Meta tag za Open Graph */}
            <meta property="og:description" content={post.excerpt.rendered} />
            <meta property="og:url" content={`https://conciergeoffootball.com/post/${id}`} />
            {/* Dodaj još meta tagova ako je potrebno */}
          </Helmet>
          <h1 className={styles.title}>{post.title.rendered}</h1>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
        </>
      ) : (
        <p>Post nije pronađen.</p>
      )}
      <BlogTestimonials />
    </div>
  );
};

export default PostDetail;
