import {combineReducers, applyMiddleware } from 'redux';
import { legacy_createStore as createStore } from "redux";
import { thunk } from 'redux-thunk';
import rootReducer from './reducers/postsReducer'; // Pretpostavljamo da imate korenski reducer u 'reducers.js' fajlu

import { postsReducer, blogReducer, categoryReducer } from "./reducers/postsReducer";

const reducer = combineReducers({
  posts: postsReducer,
  post: blogReducer,
  category: categoryReducer,
  
  });

const store = createStore(
  reducer, // Korenski reducer
  applyMiddleware(thunk) // Middleware (Redux Thunk)
);

export default store;
