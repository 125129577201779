import React,{useState} from "react";
import "./EbookScreen.css"
import { Container, Row, Col, Button,Form } from "react-bootstrap";
import CustomButton from "../Components/CustomButton";

const EbookScreen = ()=>{
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
      tip: "submit form",
      role: "",
      email: ""
  
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // Pozovite servis za slanje podataka
        const response = await fetch("https://formspree.io/f/xnqebwrj", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        });
  
        if (!response.ok) {
            throw new Error("Error sending data");
        }
  
        // Resetujte formu nakon slanja
        setFormData({
            tip: "submit form",
            email: "",
            role: ""
        });
        setSubmitted(true);
        // Dodajte kod za prikazivanje uspešne poruke ili preusmerenje korisnika
    } catch (error) {
        console.error("Error sending data:", error);
        // Dodajte kod za prikazivanje greške ili upravljanje greškom
    }
  };
    return (
        <div style={{ backgroundColor: "#0E3747", marginTop: "5rem"}}>
            <Row >
                <Col className="firstCol" md={6} xs={12}>
                    <h1 className="mainTitle">5 steps to turn your LinkedIn
                        engagement into 
                        <span className="ebook-highlight-part">booked </span>
                        <span className="ebook-highlight-part"> meetings</span>
                        &
                        <span className="ebook-highlight-part"> closed deals</span>
                         </h1>
                        <h4 className="subtitle">What you’ll find in the playbook :</h4>
                        <div className="list">
                            <Row><Col md={1} xs={2}><img src="/images/check.png" style={{height:"30px"}}></img></Col><Col md={11} xs={10}><p>One of the most effective ways to position yourself as an expert on LinkedIn</p></Col></Row>
                            <Row><Col md={1} xs={2}><img src="/images/check.png" style={{height:"30px"}}></img></Col><Col md={11}  xs={10}><p>How to define your posts’ goals, audience & topics</p></Col></Row>
                            <Row><Col md={1} xs={2}><img src="/images/check.png" style={{height:"30px"}}></img></Col><Col md={11}  xs={10}><p>How to build credibility first on LinkedIn and get $$$</p></Col></Row>
                            <Row><Col md={1} xs={2}><img src="/images/check.png" style={{height:"30px"}}></img></Col><Col md={11}  xs={10}><p>How to build credibility first on LinkedIn and get $$$</p></Col></Row>
                            <Row><Col md={1} xs={2}><img src="/images/check.png" style={{height:"30px"}}></img></Col><Col md={11}  xs={10}><p>How to build credibility first on LinkedIn and get $$$</p></Col></Row>
                        </div>
                </Col>
                <Col className="text-center   secondCol justify-content-center"  md={6} xs={12}>
                <div className="formDiv text-center justify-content-center">
        <h4 className="fw-bold">Get your free playbook to turn your LinkedIn engagement into $$$</h4>
        <h6 className="py-2 subtitle">Step-by-step framework to book 35 meetings in 30 days</h6>
        <Row className="mt-4  justify-content-center align-items-center" >
            <Col md={11}><Form.Control className="custom-placeholder" size="lg" type="email" placeholder="Your Email Address" name="email" style={{backgroundColor:"transparent", }} 
                                        onChange={handleChange} /></Col>
           

        </Row>
        <Row className="mt-4  justify-content-center align-items-center " >
        <Col md={11}><Form.Control size="lg"  type="text" name="role" value={formData.role} placeholder="Your Name" onChange={handleChange} style={{backgroundColor:"transparent",}} className="custom-placeholder" /></Col>
        </Row>
        <Row className="my-2"><Col><CustomButton onClick={handleSubmit} label={"Access your free ebook"} className="custom-button-primary py-3" /></Col></Row>
        {submitted && <p style={{ color: "green" }}>Successfully submitted!</p>}
        </div>
        </Col>

            </Row>
        </div>
    )
}

export default EbookScreen;