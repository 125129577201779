import React from 'react';
import RecentPosts from '../Components/Blog/RecentPosts';
import BlogHeader from '../Components/Blog/BlogHeader';
import FilteredPosts from '../Components/Blog/FilteredPosts';
import './BlogScreen.css';
import BlogTestimonials from '../Components/Blog/BlogTestimonials';
import BlogVideosComponent from '../Components/Blog/BlogVideosComponent';
import BlogEbookComponent from '../Components/Blog/BlogEbookComponent'
function BlogScreen() {
  return (
    <div className="blog-screen">
      <div style={{backgroundColor:"#0E3747"}}><BlogHeader /></div>
      <div style={{backgroundColor:"#0E3747", paddingTop:"3rem"}}><RecentPosts /></div>
      <div style={{backgroundColor:"#0E3747"}}><FilteredPosts /></div>
      {/* <div style={{backgroundColor:"#0E3747", padding:"5rem 0rem "}}><BlogEbookComponent /></div>
      <div style={{backgroundColor:"#0E3747", padding:"5rem 0rem "}}><BlogVideosComponent /></div> */}
      <div style={{backgroundColor:"#0E3747", paddingTop:"100px"}}><BlogTestimonials /></div>
    </div>
  );
}

export default BlogScreen;
