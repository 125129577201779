import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import srbTranslation from './locales/srb.json';

// Inicijalizacija i18next sa postavljenim jezikom iz localStorage ili podrazumevanim jezikom 'srb'
const savedLanguage = localStorage.getItem('language') || 'srb';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    srb: { translation: srbTranslation },
  },
  lng: savedLanguage, // Jezik se postavlja iz localStorage ako postoji
  fallbackLng: 'srb',
  interpolation: {
    escapeValue: false, // React automatski escape-uje vrednosti
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
