import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../../actions/blogActions';
import styles from './RecentPosts.module.css'; // Import CSS module
import { Link } from 'react-router-dom';

const RecentPosts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPosts(1, 6)); // Učitajte 6 postova prilikom učitavanja komponente
  }, [dispatch]);

  const { allPosts, loading } = useSelector((state) => state.posts);

  // Sortiraj postove po datumu
  const sortedPosts = [...allPosts].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Prikazivanje tri najnovija posta
  const recentPosts = sortedPosts.slice(0, 3);

  // Funkcija koja ekstraktuje naziv kategorije iz HTML stringa
  const getCategoryNameFromHtml = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const linkElement = tempDiv.querySelector('a');
    return linkElement ? linkElement.textContent.trim().toLowerCase() : 'unknown';
  };

  return (
    <div className={styles.recentPosts}>
      {loading ? (
        <p>Učitavanje postova...</p>
      ) : (
        recentPosts.map(post => (
          <Link to={`/post/${post.id}`} key={post.id} className={styles.postCard}>
            <div className={styles.cardCategory}>{getCategoryNameFromHtml(post.rttpg_category)}</div>
            <h2 className={styles.postCardTitle}>{post.title.rendered}</h2>
            <div className={styles.postCardContent} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></div>
            <div className={styles.cardFooter}>
              <div className={styles.cardAuthor}> {post.rttpg_author.display_name}</div>
              <div className={styles.verticalLine}></div>
              <div className={styles.cardDate}>{new Date(post.date).toLocaleDateString()}</div>
            </div>
          </Link>
        ))
      )}
    </div>
  );
};

export default RecentPosts;
