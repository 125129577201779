import React from "react";
import { Col, Row} from "react-bootstrap";
import styles from "./BlogCommentsCard.css"

const BlogCommentsCard = ({image, ProfileName,ProfileComment})=>{

    return(

        <div>
        <Row><Col><h4 className="tekst">{ProfileComment}</h4></Col></Row>
        <Row className="imageNameCard mt-5">
            <Col md={2} xs={3}>{image && <img src={image} alt="Review" style={style.image} />}</Col>
            <Col md={8} xs={9}>{ProfileName}</Col>
        </Row>

        </div>
    )
}
const style={
    image: {
        width: '35px',
        height:"35px",
        marginBottom: '',
        textAlign: '',
        padding: '0 '
      },
}

export default BlogCommentsCard;