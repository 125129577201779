import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import CoFButton from '../Button/CoFButton.js';
import "./SecondHeader.css";
import { useTranslation } from "react-i18next";

const SecondHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const { t } = useTranslation();
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
        window.scrollTo(0, 0);
    };

    // Funkcija za ažuriranje širine prozora
    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    };

    // Dodajemo event listener za promenu veličine prozora
    useEffect(() => {
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions);
    }, []);


    const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

// Dodajte putanje za prijavu i registraciju na base URL
const signUpUrl = `${appBaseUrl}/signup`;
const signInUrl = `${appBaseUrl}/signin`;

// Handler funkcija za klik na dugme "Sign In"
const handleSignUpClick = () => {
  // Preusmeravanje na stranicu za registraciju
  window.location.href = signUpUrl;
};

const handleSignInClick = () => {
  // Preusmeravanje na stranicu za prijavljivanje
  window.location.href = signInUrl;
};

    return (
        <Row style={{ height: "80px", backgroundColor: "#0d3340", position: "fixed", 
        top: "0px", width: "100%", marginLeft: "0px", zIndex: "1000" }} 
        className="align-items-center">
            <Col lg={3} xs={6} className="d-flex align-items-center"
            style={{ justifyContent: window.innerWidth <= 992 ? 'left' : 'center' }}>
                <Link to="/" className="logo-link">
                    <img src="/images/logo.png" alt="Logo" className="logo" />
                </Link>
            </Col>

            <Col lg={6} xs={0} className="d-none d-lg-flex align-items-center justify-content-center">
                <nav className="desktop-nav">
                    <Link to="/solutions" onClick={closeMenu} className={location.pathname === "/solutions" ? "navbar-link-active" : ""}>{t("Upotreba")}</Link>
                    <Link to="/whyuse" onClick={closeMenu} className={location.pathname === "/whyuse" ? "navbar-link-active" : ""}>{t("Zasto COF")}</Link>
                    <Link to="/company" onClick={closeMenu} className={location.pathname === "/company" ? "navbar-link-active" : ""}>{t("O nama")}</Link>
                    <Link to="/demo" onClick={closeMenu} className={location.pathname === "/demo" ? "navbar-link-active" : ""}>{t("Zakazi demo")}</Link>
                    <Link to="/blog" onClick={closeMenu} className={location.pathname === "/blog" ? "navbar-link-active" : ""}>{t("Blog")}</Link>
                </nav>
            </Col>

            <Col lg={3} xs={6} className="d-flex align-items-center justify-content-end">
                <CoFButton buttonText={t("Uloguj se" )} onClick={handleSignInClick}/>
                <div style={{ width: "10px" }} />
                {windowWidth > 992 ? ( // Prikaži dugme samo na većim ekranima
                    <CoFButton buttonText={t("Registruj se")} hasBorder={true} borderColor="green" className="signup-button" onClick={handleSignUpClick}/>
                ) : null}
                <div style={{ width: "10px" }} />
                <div className="nav-btn" onClick={toggleMenu}>
                    {isOpen ? <FaTimes /> : <FaBars />}
                </div>
            </Col>

            <Col xs={12} className={isOpen ? "mobile-menu open" : "mobile-menu"}>
                <nav className="mobile-nav">
                    <Link to="/solutions" onClick={closeMenu} className={location.pathname === "/solutions" ? "navbar-link-active" : ""}>{t("Upotreba")}</Link>
                    <Link to="/whyuse" onClick={closeMenu} className={location.pathname === "/whyuse" ? "navbar-link-active" : ""}>{t("Zasto COF")}</Link>
                    <Link to="/company" onClick={closeMenu} className={location.pathname === "/company" ? "navbar-link-active" : ""}>{t("O nama")}</Link>
                    <Link to="/demo" onClick={closeMenu} className={location.pathname === "/demo" ? "navbar-link-active" : ""}>{t("Zakazi demo")}</Link>
                    <Link to="/blog" onClick={closeMenu} className={location.pathname === "/blog" ? "navbar-link-active" : ""}>{t("Blog")}</Link>
                </nav>
            </Col>
        </Row>
    );
};

export default SecondHeader;
