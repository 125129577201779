import axios from "axios";


import {
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  SET_PAGE,

  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,

  FETCH_CATEGORY_POSTS_REQUEST,
  FETCH_CATEGORY_POSTS_SUCCESS,
  FETCH_CATEGORY_POSTS_FAILURE,

  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from "../constants/blogConstants";

export const fetchPosts = (page = 1) => {
  return dispatch => {
    dispatch({ type: FETCH_POSTS_REQUEST });

    axios.get(`https://blog-admin.conciergeoffootball.com/wp-json/wp/v2/posts?per_page=6&page=${page}`)
      .then(response => {
        dispatch({
          type: FETCH_POSTS_SUCCESS,
          payload: response.data,
          page
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_POSTS_FAILURE,
          payload: error.message
        });
      });
  };
};

export const fetchPost = (id) => {
  return dispatch => {
    dispatch({ type: FETCH_POST_REQUEST });

    axios.get(`https://blog-admin.conciergeoffootball.com/wp-json/wp/v2/posts/${id}`)
      .then(response => {
        dispatch({
          type: FETCH_POST_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_POST_FAILURE,
          payload: error.message
        });
      });
  };
};

export const setPage = (page) => ({
  type: SET_PAGE,
  page
});


export const fetchCategories = () => {
  return dispatch => {
    dispatch({ type: FETCH_CATEGORIES_REQUEST });

    axios.get(`https://blog-admin.conciergeoffootball.com/wp-json/wp/v2/categories`)
      .then(response => {
        dispatch({
          type: FETCH_CATEGORIES_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_CATEGORIES_FAILURE,
          payload: error.message
        });
      });
  };
};

export const fetchPostsBySelectedCategory = (categoryId) => {
  return dispatch => {
    dispatch({ type: FETCH_CATEGORY_POSTS_REQUEST });

    axios.get(`https://blog-admin.conciergeoffootball.com/wp-json/wp/v2/posts?categories=${categoryId}`)
      .then(response => {
        dispatch({
          type: FETCH_CATEGORY_POSTS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_CATEGORY_POSTS_FAILURE,
          payload: error.message
        });
      });
  };
};
