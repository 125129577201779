import React, { useState } from "react";
import "./BlogTestimonials.css";
import { Col, Row, Carousel, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import BlogCommentsCard from "./BlogCommentsCard";
import Form from 'react-bootstrap/Form';

const BlogTestimonials = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    tip: "submit form",
    role: "",
    email: ""
  });

  const [carouselIndex, setCarouselIndex] = useState(0); // Track the current carousel index

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex); // Update the carousel index when a slide changes
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://formspree.io/f/xnqebwrj", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error("Error sending data");
      }

      setFormData({
        tip: "submit form",
        email: "",
        role: ""
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  // Handle the previous and next buttons
  const handlePrev = () => {
    setCarouselIndex((prevIndex) =>
      prevIndex === 0 ? 2 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCarouselIndex((prevIndex) =>
      prevIndex === 2 ? 0 : prevIndex + 1
    );
  };

  // Custom carousel dots component
  const renderCustomDots = () => {
    const totalSlides = 3; // Update this based on the number of carousel items
    const dots = [];

    for (let i = 0; i < totalSlides; i++) {
      dots.push(
        <span
          key={i}
          onClick={() => setCarouselIndex(i)} // Update index when a dot is clicked
          className={`custom-dot ${i === carouselIndex ? 'active' : ''}`} // Add active class for the current dot
        ></span>
      );
    }

    return <div className="custom-dots-container">{dots}</div>;
  };

  return (
    <div>
      <Row className="justify-content-center align-items-center mainRow ">
        <Col className="text-center firstDiv d-flex flex-column" md={5}>
          <div className="carousel-container flex-grow-1">
            <Carousel
              activeIndex={carouselIndex}
              onSelect={handleSelect}
              controls={false}
              indicators={false}
            >
              <Carousel.Item>
                <BlogCommentsCard
                  ProfileComment={t("Danas na jednostavan nacin, kroz svakodnevni uvid, znamo koje su radnje preduzete u klubu, a koje u nasoj ambulanti, gde je potpunosti iskljucujeno postojanje nezeljenih efekata.")}
                  ProfileName={t("Zdravko Marinković")}
                  image={"/images/derby.png"}
                />
              </Carousel.Item>
              <Carousel.Item>
                <BlogCommentsCard
                  ProfileComment={t("CoF mi je pruzio mogucnost da u realnom vremenu svakog dana znam koliko cu dece imati na treningu radi plana i organizacije samog treninga.")}
                  ProfileName={t("Milos Bajic")}
                  image={"/images/paypal.png"}
                />
              </Carousel.Item>
              <Carousel.Item>
                <BlogCommentsCard
                  ProfileComment={t("Nekada imam i 4 treninga dnevno,samo oko 2h dnevno trošim na samu organizaciju i koordinaciju, danas sve završavam kroz 15 minuta uz vrlo jednostavan način upotrebe")}
                  ProfileName={t("Bojan Miletic")}
                  image={"/images/shortcut-script-app.png"}
                />
              </Carousel.Item>
            </Carousel>
            {/* Render custom dots and buttons */}
            <Row className="custom-controls">
              <Col className="d-flex justify-content-start">
                {renderCustomDots()}
              </Col>
              <Col className="d-flex justify-content-end">
                <Button className="arrow-button-left-testemonials me-2" onClick={handlePrev}>
                  <img src="images/right-arrow-carousel.png" alt="Previous" />
                </Button>
                <Button className="arrow-button-right-testemonials" onClick={handleNext}>
                  <img src="images/right-arrow-carousel.png" alt="Next" />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="secondDiv" md={6}>
          <h1 className="fw-bold">
          {t("Ostani informisan o svim aktuelnostima i trendovima u fudbalu. Pretplati se na nas")}  <br />
            <span className="highlight-part">{t("newsletter")}</span>
            <br />
            {t("i dobijaj pazljivo odabrane informacije od stane CoF tima.")}

          </h1>
          <Row className="mt-4 px-3 justify-content-center align-items-center">
            <Col md={6}>
              <Form.Control
                size="lg"
                type="email"
                placeholder="Your Email Address"
                name="email"
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <Form.Control
                size="lg"
                type="text"
                name="role"
                value={formData.role}
                placeholder="Your Name"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="text-center">
              <CustomButton
                onClick={handleSubmit}
                label={"Subscribe to our newsletter"}
                className="custom-button-primary py-3"
              />
            </Col>
          </Row>
          {submitted && <p style={{ color: "green" }}>Successfully submitted!</p>}
        </Col>
      </Row>
    </div>
  );
};

export default BlogTestimonials;
