import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts, setPage, fetchPostsBySelectedCategory } from '../../actions/blogActions';
import { fetchCategories } from '../../actions/blogActions';
import styles from './FilteredPosts.module.css'; // Import CSS module
import { Link } from 'react-router-dom';
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";

const FilteredPosts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [category, setCategory] = useState('all');
  const [searchText, setSearchText] = useState('');

  // Pristupamo postovima i kategorijama iz store-a
  const { allPosts, categoryPosts, loading: postsLoading, page } = useSelector(state => state.posts);
  const { categories, loading: categoriesLoading, error: categoriesError } = useSelector(state => state.category);

  useEffect(() => {
    dispatch(fetchCategories()); // Učitaj kategorije prilikom učitavanja komponente
  }, [dispatch]);

  useEffect(() => {
    if (category !== 'all') {
      dispatch(fetchPostsBySelectedCategory(category));
    } else {
      dispatch(fetchPosts(page, 6)); // Pozovite prvu stranicu sa 6 postova ako je izabrana opcija "Svi"
    }
  }, [category, dispatch, page]);

  const getCategoryNameFromHtml = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const linkElement = tempDiv.querySelector('a');
    return linkElement ? linkElement.textContent.trim().toLowerCase() : 'unknown';
  };

  const filteredBySearch = (posts) => {
    return posts.filter(post =>
      post.title.rendered.toLowerCase().includes(searchText.toLowerCase()) ||
      post.excerpt.rendered.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const postsToShow = searchText ? filteredBySearch(category !== 'all' ? categoryPosts : allPosts) : (category !== 'all' ? categoryPosts : allPosts);

  const loadMore = () => {
    dispatch(setPage(page + 1));
  };

  return (
    <div className={`${styles.filteredPosts} py-5 wholeDiv`}>
      <nav className={styles.categoryNav}>
        {categoriesLoading ? (
          <p>Učitavanje kategorija...</p>
        ) : categoriesError ? (
          <p>Greška prilikom učitavanja kategorija: {categoriesError}</p>
        ) : (
          <>
            <button
              className={`${styles.categoryNavButton} ${category === 'all' ? styles.categoryNavButtonActive : ''}`}
              onClick={() => setCategory('all')}
            >
              Svi
            </button>
            {categories.map(cat => (
              <button
                key={cat.id}
                className={`${styles.categoryNavButton} ${category === cat.id.toString() ? styles.categoryNavButtonActive : ''}`}
                onClick={() => setCategory(cat.id.toString())}
              >
                {cat.name}
              </button>
            ))}
          </>
        )}
      </nav>
      <div className={styles.searchContainer}>
        <h1 className={styles.title}>Biblioteka</h1>
        <div className={styles.searchWrapper}>
          <input
            type="text"
            placeholder="Pretraži postove..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className={styles.searchInput}
          />
          <i className={`fas fa-search ${styles.searchIcon}`}></i>
        </div>
      </div>
      <div className={styles.postCards}>
        {postsToShow.length > 0 ? (
          postsToShow.map(post => (
            <Link to={`/post/${post.id}`} key={post.id} className={styles.postCard}>
              <div className={styles.cardCategory}>{getCategoryNameFromHtml(post.rttpg_category)}</div>
              <h2 className={styles.postCardTitle}>{post.title.rendered}</h2>
              <div className={styles.postCardContent} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></div>
              <div className={styles.cardFooter}>
                <div className={styles.cardAuthor}> {post.rttpg_author.display_name}</div>
                <div className={styles.verticalLine}></div>
                <div className={styles.cardDate}>{new Date(post.date).toLocaleDateString()}</div>
              </div>
            </Link>
          ))
        ) : (
          postsLoading ? <p style={{color:"white"}}>{t('Učitavanje postova...')}</p> : <p style={{color:"white"}}>{t('Nema dostupnih postova.')}</p>
        )}
      </div>
      {category === 'all' && (
  <div className={styles.loadMoreButtonContainer}>
    <CustomButton
      onClick={loadMore}
      disabled={postsLoading}
      className={styles.loadMoreButton}
      label={postsLoading ? t('Učitavanje...') : t('Učitaj više')}
    />
  </div>
)}
    </div>
  );
};

export default FilteredPosts;
