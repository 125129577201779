import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomeScreen from './Screens/HomeScreen';
import Solutions from './Screens/Solutions';
import BlogScreen from './Screens/BlogScreen';
import TryDemoScreen from './Screens/TryDemoScreen';
import WhyUseScreen from './Screens/WhyUseScreen';
import CompanyScreen from './Screens/CompanyScreen';
import PostDetail from './Components/PostDetail';
import SecondHeader from './Components/SecondHeader/SecondHeader';
import Footer from './Components/Footer/Footer';
import EbookScreen from './Screens/EbookScreen';

const App = () => {
  return (
    <Router>
      <SecondHeader />
      <main>
        <Routes>
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/solutions" element={<Solutions />} exact />
          <Route path="/blog" element={<BlogScreen />} exact />
          <Route path="/post/:id" element={<PostDetail />} />
          <Route path="/demo" element={<TryDemoScreen />} exact />
          <Route path="/whyuse" element={<WhyUseScreen />} exact />
          <Route path="/company" element={<CompanyScreen />} exact />
          <Route path="/ebook" element={<EbookScreen />} exact />

        </Routes>
        <Footer />
      </main>
    </Router>
  );
}

export default App;
