import {
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  SET_PAGE,

  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,

  FETCH_CATEGORY_POSTS_REQUEST,
  FETCH_CATEGORY_POSTS_SUCCESS,
  FETCH_CATEGORY_POSTS_FAILURE,

  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from "../constants/blogConstants";


const initialState = {
  allPosts: [],  // Za sve postove
  categoryPosts: [],  // Za postove po kategoriji
  loading: false,
  error: null,
  post: null,
  page: 1,
  hasMore: true
};

export const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_REQUEST:
    case FETCH_CATEGORY_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allPosts: [
          ...state.allPosts,
          ...action.payload.filter(post => !state.allPosts.some(existingPost => existingPost.id === post.id))
        ],
        page: action.page || state.page,
        hasMore: action.payload.length > 0,
        error: null,
      };
    case FETCH_CATEGORY_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryPosts: action.payload, // Ovde postavljamo postove po kategoriji
        error: null,
      };
    case FETCH_POSTS_FAILURE:
    case FETCH_CATEGORY_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
};
const postinitialState = {
  posts: [],
  loading: false,
  error: null,
  post: null, // Dodajte ovo za pojedinačni post
  page: 1, // Dodajte početno stanje za stranicu
};

export const blogReducer = (state = postinitialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: [...state.posts, ...action.payload], // Dodajte nove postove na postojeći niz
      };
    case FETCH_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.payload,
      };
    case FETCH_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};

const categoryInitialState = {
  categories: [],
  loading: false,
  error: null,
};

export const categoryReducer = (state = categoryInitialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};